// Here you can add other styles
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.show_password {
  display: block;
  width: 15px;
  height: 15px;
  background: url(/show_password.svg);
  /* background-size: 15px 15px; */
}
.hide_password {
  display: block;
  width: 15px;
  height: 15px;
  background: url(/hide_password.svg);
  background-size: 15px 15px;
}

.filter-heading-bold {
  font-weight: bold;
}

.ReactModalPortal .ReactModal__Overlay {
  z-index: 2000 !important;
}

.buttonConfirm {
  display: flex;
  gap: 8px;
  margin-top: 15px;
  justify-content: center;
  .btn {
    min-width: 100px;
  }
}
